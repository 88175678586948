<template>
  <ul v-show="!isLoading" class="nav__account">
    <template v-if="isAuthenticated">
      <!-- <li><base-link class="nav__link" to="/account">Account</base-link></li> -->
      <ui-button class="has-no-border" tag="app-link" to="/logout">Logout</ui-button>
    </template>
    <template v-else>
      <li>
        <button class="nav__link" @click.prevent="login()">Login</button>
      </li>
    </template>
  </ul>
</template>

<script setup lang="ts">
import BaseLink from "~/components/global/ui/BaseLink.vue";

import { computed, useAuth } from "#imports";

const { auth } = useAuth();

const isAuthenticated = computed(() => auth.isAuthenticated.value);

const isLoading = computed(() => auth.isLoading.value);

const login = () => {
  auth.loginWithRedirect({
    appState: { target: "/account" },
  });
};
</script>

<style lang="scss" scoped>
.nav__account {
  a {
    background-color: #4169e1;
    color: white;
  }
}

.nav__account a:hover {
  background-color: darken(#4169e1, 10%);
}
</style>
